import OfficeSelect from "./OfficeSelect";

const PersonalDetails = ({ data, isOfficeSelect }: any) => {

    const { employee, officeCollectID } = data;
    return (
        <div className="details d-title">
            <span className=""><h2>Welcome {`${employee.firstName} ${employee.lastName}`}</h2> </span>
               {isOfficeSelect && 'Collect gift from:'}
           
          {isOfficeSelect && <OfficeSelect defaultValue={officeCollectID} officeID={employee.officeID} />}
        </div>
    );
}

export default PersonalDetails;
