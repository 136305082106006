import { useEffect, useState } from "react";
import config from "../config";
import { useAuth } from "../context/Auth";
import fetcher from "../utils/fetcher";
import Loading from "./Loading";
import "../custom.css";
import GiftForm from "./GiftForm";

const Home = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState<any>(null);
  const [employee, setEmployee] = useState<any>(null);

  useEffect(() => {
    if (user) {
      const loginAs = window.location ? new URLSearchParams(window.location.search).get("as") : null;
      const mail = loginAs || user.mail;
      const url = `${config.apiUrl}/Event/GetEmployeeActiveEvents/${mail}`;
      fetcher
        .get(url)
        .then((response) => response.json())
        .then(({ activeEvents, employee }) => {
          setEvent(activeEvents.find((e: any) => e.isEnabledSelection));
          setEmployee(employee);
          setLoading(false);
        });
    }
  }, [user]);

  if (loading) return <Loading />;

  if (event && event.isEnabledSelection)
    return <GiftForm employee={employee} event={event} />;

  if (event && !event.isEnabledSelection)
    return (
      <div className="inst-text">
        The Link is closed for submission. <br />
        For any issues contact
        <a href="mailto:chagim@citybook.co.il" color="secondary">
          {" "}
          chagim@citybook.co.il{" "}
        </a>
      </div>
    );
  return (
    <div className="inst-text">
      <br />

The Link is closed for submission.  
     
        <span className="dontsh">For any issues contact
        <a href="mailto:nwolbe@citybook.co.il" color="secondary">
          {" "}
        nwolbe@citybook.co.il{" "}
          </a></span>
       <br />
          <br />
          <br />
          <br />
    </div>
  );
};

export default Home;
